.App {
  background-color: var(--color-primary);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

a {
  color: var(--color-body-dark);
}