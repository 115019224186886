.media {
    max-width: 18em;
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-inline: auto;
    padding-inline: 1em;

    @media only screen and (min-width: 728px) {
        max-width: 30em;
    }
    
    
    img {
        max-width: 15em;
        border-radius: 100%;
        margin-bottom: 2rem;
    }

    .skill-icon {
        margin-bottom: 2rem;
    }

    h4 {
        margin-bottom: 1.8rem;
    }

    p {
        text-align: left;
        margin: auto;
        margin-bottom: 1.8rem;
    }

    a {
        color: var(--color-body-dark);
        text-decoration: none;
    }
}