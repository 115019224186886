#more-projects {
    .projects {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        @media (min-width: 728px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(6, 1fr);
        }

        @media (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }

        h2 {
            font-size: 3rem;
        }

        h4 {
            font-size: 1.6rem;
            @media (min-width: 728px) {
                font-size: 2rem;
            }
        }

        p {
            font-size: 1.2rem;
            line-height: 2rem;

            @media (min-width: 728px) {
                font-size: 1.4rem;
            }
        }
    }
}