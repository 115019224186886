#projects {
    margin-top: 6rem;
    background-color: var(--color-dark-bkgrnd);
    border-radius: 5px;
    padding: 2rem;
    
    h2 {
        color: var(--color-primary);
        font-size: 3rem;
    }

    a {
        color: var(--color-primary);
    }

    .link {
        color: var(--color-primary);
        text-decoration: none;
        font-size: 1.2rem;
        position: relative;
        top: -20px;
        padding: 0.5rem 1rem;
        border-radius: 5px;

        &:hover {
            background-color: var(--color-light-bkgrnd);
            color: var(--color-body-dark);
        }
    }

    .projects {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        @media (min-width: 728px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }

        @media (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        h4 {
            font-size: 1.6rem;
            @media (min-width: 728px) {
                font-size: 2rem;
            }
        }

        p {
            font-size: 1.4rem;
            @media (min-width: 728px) {
                font-size: 1.6rem;
            }
        }
    }
}