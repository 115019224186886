.bucket-list {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    @media (min-width: 1200px) {
        flex-direction: row;
    }

    &::-webkit-scrollbar {
        width: 0.5em;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .list {
        margin-inline: auto;
        @media (min-width: 768px) {
            margin-bottom: 12rem;
        }
    }

    p {
        font-size: 1.2rem;
        line-height: 2rem;

        @media (min-width: 768px) {
            max-width: 50%;
            margin: auto;
        }
    }

    ul {
        font-size: 1.2rem;
        line-height: 2rem;
        margin-inline: auto;

        @media (min-width: 425px) {
            width: 50%;
        }
    }

    ul, li {
        list-style: none;
    }

    .complete {
        list-style-image: url('/assets/check-mark.svg') !important;
    }

    .photo-grid {
        position: relative;
        max-width: 50vw;
        height: 80rem;
        top: 20%;
        left: 10%;
        margin: 5%;
        @media (max-width: 525px) {
            height: 50rem;
            left: 3%;
        }
        @media (min-width: 1200px) {
            position: sticky;
            height: 80rem;
            top: 20%;
            width: 30vw;
        }
    }

    .polaroid {
        width: 18.75rem;
        height: 22.5rem;
        background: #FFF;
        position: absolute; 
        top: 3.125rem;
        margin: auto;
        background: linear-gradient(120deg, #fff, #DDD 60%);
        box-shadow: 2px -2px 20px 0px rgba(0,0,0,0.6), inset 4px 5px 10px 0 rgba(0,0,0,0.1);
        -webkit-backface-visibility: hidden;
        &:nth-child(1) {
            left: 15.625rem;
            transform: rotate(10deg);
            @media (max-width: 425px) {
                left: 5.625rem;
            }
        }
        
          &:nth-child(2) {
            top: 12.5rem;
            transform: rotate(-15deg);
        }
        
          &:nth-child(3) {
            left: 15.625rem;
            top: 25rem;
            transform: rotate(5deg);
            @media (max-width: 425px) {
                left: 5.625rem;
            }
        }

        .polaroid-img {
            position: absolute;
            top: 1.25rem;
            left: 0.625rem;
            width: 17.5rem;
            height: 17.5rem;
            background: #eee;
            overflow: hidden;
            img {
              width: 100%;
              z-index: -1;
            }
          }
          
          .gloss {
            position: absolute; 
            top: 0;
            left: 0;
            background: linear-gradient(120deg, rgba(255,255,255,.6), transparent 60%, rgba(0,0,0,.5) 99%);  
            box-shadow: inset 0 0 10px 1px rgba(0,0,0,0.2);
            width: 100%;
            height: 100%;
            z-index: 10; 
          }
          
          p {
            position: absolute;
            bottom: -1.313rem;
            padding: 1.938rem 4.5rem;
            letter-spacing: .1em;
            white-space: nowrap;
          }    
      }
}