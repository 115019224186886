#skills {
    margin-top: 6rem;
    border-radius: 5px;
    padding: 2rem;
    
    h2 {
        color: var(--color-body-dark);
        font-size: 3rem;
    }

    .skills {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        @media (min-width: 728px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }

        @media (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        .skill-icon {
            background-color: var(--color-body-dark);
            fill: var(--color-primary);
            padding: 2.5rem;
            border-radius: 100%;
        }

        h4 {
            font-size: 1.6rem;
            @media (min-width: 728px) {
                font-size: 2rem;
            }
        }

        p {
            font-size: 1.4rem;
            @media (min-width: 728px) {
                font-size: 1.6rem;
            }
        }
    }
}