.py-tracking {
    height: 90vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 10%;

    video {
        margin-inline: auto;
        margin-bottom: 3rem;

        @media (max-width: 375px) {
            max-width: 70%;
        }
    }

    a {
        color: var(--color-body-dark);
    }

    p {
        line-height: 1.6rem;
    }
}