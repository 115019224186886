.btn {
    border: 0.8px solid var(--color-dark-bkgrnd);
    padding: 1.2rem 2rem;
    color: var(--color-body-dark);
    text-decoration: none;

    &:hover {
        background-color: var(--color-dark-bkgrnd);
        color: var(--color-body-lighter);
    }
}