.navbar {
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    background-color: var(--color-primary);
    
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 0;
        padding: 0;

        @media (min-width: 768px) {
            width: 25vw;
            justify-content: space-between;
        }

        li {
            list-style-type: none;
            font-size: 2rem;

            &:first-of-type {
                border: 2px solid var(--color-body-dark);
                border-radius: 2.5px;
                padding: 0.2rem 0.5rem;

                &:hover {
                    background-color: var(--color-body-dark);
                    transition: 0.8s;

                    a {
                        color: var(--color-body-lighter);
                    }
                }
            }

            a {
                color: var(--color-body-dark)
            }

            a:hover {
                color: var(--color-body-darker)
            }

            .link {
                text-decoration: none;
                text-transform: uppercase;
            }
        }
    }
}