.books {
    margin: auto;
    min-height: 100vh;
    margin-top: 10rem;
    margin-bottom: 20rem;

    @media (min-width: 425px) {
        width: 75%;
    }

    @media (min-width: 768px) {
        width: 50%;
    }

    h3 {
        font-size: 1.5rem;
    }

    p {
        max-width: 80rem;
        font-size: 1.4rem;
    }

    .samples {
        display: flex;
        flex-direction: column;

        @media (min-width: 425px) {
            flex-direction: row;
        }
    }
}

.book {
    min-height: 100vh;
    margin-top: 10rem;
    margin-bottom: 20rem;

    img {
        max-width: 80vw;
        border-radius: 5px;
        margin-inline: auto;
        display: flex;
        margin-bottom: 3rem;

        @media (min-width: 425px) {
            max-width: 60vw;
        }

        @media (min-width: 768px) {
            max-width: 45vw;
        }

        @media (min-width: 1024px) {
            max-width: 30vw;
        }
    }

    h2 {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 3rem;
    }

    p {
        max-width: 80rem;
        font-size: 1.4rem;
        margin-inline: auto;
        line-height: 2rem;
    }
}