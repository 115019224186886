h1, 
h2, 
h3, 
h4, 
h5, 
h6, 
a {
    font-family: 'IBM Plex Mono', monospace;
}

p {
    font-family: 'Inconsolata', monospace;
}