.hero {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 50vh;

    @media (min-width: 1024px) {
        flex-direction: row;
    }

    img {
        max-width: 300px;
        display: flex;
        margin: auto;

        @media (min-width: 728px) {
            max-width: 500px;
        }
        
        @media (min-width: 1024px) {
            max-width: 600px;
            margin: unset;
        }
    }

    &-content {
        margin-top: 10%;
        color: var(--color-body-dark);

        h1 {
            text-align: left;
            font-size: 3rem;
            margin: 0;

            @media (min-width: 1024px) {
                font-size: 6rem;
            }
        }

        p {
            font-size: 1rem;
            line-height: 2rem;
            max-width: 60rem;

            @media (min-width: 1024px) {
                font-size: 1.5rem;
                line-height: 3rem;
            }
        }

        .icon-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            max-width: 12rem;
            
            a {
                color: var(--color-dark-bkgrnd);
            }
        }

        .hero-links {
            margin-top: 2.5rem;
        }
    }    
}